import React, { useState } from 'react';
import { Box, Tabs, Tab, AppBar, IconButton, Typography, Paper } from '@mui/material';
import BusRouteSearch from './BusRouteSearch';
import { Schedule, DirectionsBus, ArrowBack } from '@mui/icons-material';
import CardBus from '../cardBus';
import CustomBusStatusWrapper from './CustomBusStatusWrapper';
import Forum from '../forum/Forum';

const BusTabView = ({ updateBottomNavigationTab, displayFullScreen }) => {
  const [tabValue, setTabValue] = useState(0);
  
  // Bus status and forum view states
  const [showBusStatusPage, setShowBusStatusPage] = useState(false);
  const [busStatusBusId, setBusStatusBusId] = useState(null);
  const [busStatusBusInfo, setBusStatusBusInfo] = useState(null);
  const [forum, setForum] = useState(false);
  const [showBusForumPage, setShowBusForumPage] = useState(false);
  const [busForumBusId, setBusForumBusId] = useState(null);
  const [busForumBusInfo, setBusForumBusInfo] = useState(null);
  
  // State to remember search parameters and results
  const [searchParams, setSearchParams] = useState({
    startStop: '',
    endStop: '',
    hasSearched: false
  });
  const [previousTabValue, setPreviousTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Handlers for bus status and forum views
  const updateBusStatusPage = (showStatus, busId, busInfo) => {
    // Remember which tab we were on
    setPreviousTabValue(tabValue);
    
    setShowBusStatusPage(showStatus);
    setBusStatusBusId(busId);
    setBusStatusBusInfo(busInfo);
    
    // Set fullscreen when showing bus status
    if (showStatus && displayFullScreen) {
      displayFullScreen(true);
    }
  };

  const updateBusForumPage = (showForum, busId, busInfo) => {
    // Remember which tab we were on
    setPreviousTabValue(tabValue);
    
    setShowBusForumPage(showForum);
    setBusForumBusId(busId);
    setBusForumBusInfo(busInfo);
    setForum(true);
    
    // Set fullscreen when showing forum
    if (showForum && displayFullScreen) {
      displayFullScreen(true);
    }
  };

  const handleBackToTabs = () => {
    setShowBusStatusPage(false);
    setShowBusForumPage(false);
    
    // Restore previous tab
    setTabValue(previousTabValue);
    
    // Return to normal tab view
    if (displayFullScreen) {
      displayFullScreen(false);
    }
  };

  // Handler to save search parameters
  const saveSearchParams = (params) => {
    setSearchParams(params);
  };

  const openLoginAlertBox = () => {
    // This function would be used for login alerts
    // Not implementing this since we're assuming the user is logged in
  };

  // Render Bus Status page if active
  if (showBusStatusPage) {
    // Add a custom wrapper around BusStatusWrapper to ensure it has proper back navigation
    return (
      <>
        {/* Custom navigation header for bus status view */}
        <AppBar 
          position="fixed" 
          elevation={0}
          sx={{ 
            top: 0, 
            bgcolor: '#5d4a7d', 
            zIndex: 1200,
            boxShadow: '0 1px 3px rgba(0,0,0,0.2)'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', p: 1.5 }}>
            <IconButton 
              edge="start" 
              color="inherit" 
              onClick={handleBackToTabs}
              sx={{ mr: 1.5 }}
              size="small"
            >
              <ArrowBack />
            </IconButton>
            <Typography 
              variant="subtitle1" 
              component="div" 
              sx={{ 
                flexGrow: 1,
                fontWeight: 500
              }}
            >
              {busStatusBusInfo}
            </Typography>
          </Box>
        </AppBar>
        <Box sx={{ pt: 7 }}>
          <CustomBusStatusWrapper 
            setShowBusStatusPage={setShowBusStatusPage} 
            busId={busStatusBusId} 
            busInfo={busStatusBusInfo} 
            openLoginAlertBox={openLoginAlertBox}
            updateBusForumPage={updateBusForumPage}
            hideHeader={true} // Add this prop to indicate we're already showing a header
          />
        </Box>
      </>
    );
  } 
  
  // Render Forum page if active
  if (showBusForumPage) {
    return (
      <>
        {/* Custom navigation header for forum view */}
        <AppBar 
          position="fixed" 
          elevation={0}
          sx={{ 
            top: 0, 
            bgcolor: '#5d4a7d', 
            zIndex: 1200,
            boxShadow: '0 1px 3px rgba(0,0,0,0.2)'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', p: 1.5 }}>
            <IconButton 
              edge="start" 
              color="inherit" 
              onClick={handleBackToTabs}
              sx={{ mr: 1.5 }}
              size="small"
            >
              <ArrowBack />
            </IconButton>
            <Typography 
              variant="subtitle1" 
              component="div" 
              sx={{ 
                flexGrow: 1,
                fontWeight: 500
              }}
            >
              {busForumBusInfo} - Discussion
            </Typography>
          </Box>
        </AppBar>
        <Box sx={{ 
          pt: 7, 
          height: 'calc(100vh - 56px)', 
          display: 'flex', 
          flexDirection: 'column',
          bgcolor: '#fafafa'
        }}>
          <Forum 
            busId={busForumBusId} 
            setBusForumBusId={setBusForumBusId} 
            setShowBusForumPage={setShowBusForumPage} 
            busInfo={busForumBusInfo}
            hideHeader={true}
          />
        </Box>
      </>
    );
  }

  // Normal tab view otherwise
  return (
    <Paper
      elevation={0}
      sx={{ 
        width: '100%', 
        maxWidth: '100vw',
        overflowX: 'hidden',
        bgcolor: '#fafafa',
        minHeight: 'calc(100vh - 56px)'
      }}
    >
      <Box sx={{ 
        borderBottom: 1, 
        borderColor: 'divider', 
        mb: 2, 
        width: '100%',
        bgcolor: 'white'
      }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          variant="fullWidth"
          textColor="primary"
          indicatorColor="primary"
          sx={{
            '& .MuiTab-root': {
              color: 'rgba(93, 74, 125, 0.7)',
              fontWeight: 500,
            },
            '& .Mui-selected': {
              color: '#5d4a7d',
              fontWeight: 600,
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#5d4a7d',
              height: 3
            }
          }}
        >
          <Tab 
            label="Bus Timetable" 
            icon={<Schedule sx={{ fontSize: '1.25rem' }} />} 
            iconPosition="start"
            sx={{ textTransform: 'none' }}
          />
          <Tab 
            label="Search Bus" 
            icon={<DirectionsBus sx={{ fontSize: '1.25rem' }} />} 
            iconPosition="start"
            sx={{ textTransform: 'none' }}
          />
        </Tabs>
      </Box>
      
      <Box 
        role="tabpanel" 
        hidden={tabValue !== 0}
        sx={{ 
          width: '100%', 
          overflow: 'hidden'
        }}
      >
        {tabValue === 0 && (
          <CardBus 
            displayBus={true} 
            updateBottomNavigationTab={updateBottomNavigationTab}
            displayFullScreen={displayFullScreen}
          />
        )}
      </Box>
      
      <Box 
        role="tabpanel" 
        hidden={tabValue !== 1}
        sx={{ 
          width: '100%', 
          overflow: 'hidden'
        }}
      >
        {tabValue === 1 && (
          <BusRouteSearch 
            updateBottomNavigationTab={updateBottomNavigationTab}
            displayFullScreen={displayFullScreen}
            updateBusStatusPage={updateBusStatusPage}
            updateBusForumPage={updateBusForumPage}
            setForum={setForum}
            savedSearchParams={searchParams}
            saveSearchParams={saveSearchParams}
          />
        )}
      </Box>
    </Paper>
  );
};

export default BusTabView; 