import React from "react"
import { useEffect, useState } from "react"
import MessageUi from './MessageUi'
import {getBusForumMessage, deleteForumMessage} from '../../api/bus'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LinearProgress, Typography, Box } from "@mui/material";
import PostMessageBox from "./PostMessageBox";
import { isLoggedIn } from "../../api/account";


export default function Forum({busId, setBusForumBusId, busInfo, setShowBusForumPage, hideHeader = false}) {
   const user = isLoggedIn();
   const [navCityColor, setNavCityColor] = useState("#800080");
   const [posts, setPosts] = useState([])
   const [updateMessage, setUpdateMessage] = useState(0)
   const [loader, setLoader] = useState(false);
   
   function scrollView() {
     const mainRoot = document.getElementById("scrollToThisMessage");
     mainRoot?.scrollIntoView({ behavior: "smooth" });
   }

   useEffect(() => {
       async function fetchData() {
           setLoader(true)
           const msg = await getBusForumMessage(busId)
           setPosts(msg)
           setTimeout(() => {
               scrollView();
               setLoader(false);
           }, 1000)
       }
       fetchData()
   }, [updateMessage])

   const deleteMessage = async (messageId) => {
       setLoader(true)
       await deleteForumMessage(busId, messageId);
       setTimeout(() => {
           refreshMessages()
           setLoader(false)
       }, 1000)
   }

   const refreshMessages = () => {
       setUpdateMessage(updateMessage + 1)
   }

   // Completely different layout when hideHeader is true
   if (hideHeader) {
       return (
           <Box sx={{ 
               display: 'flex', 
               flexDirection: 'column',
               height: '100%', 
               width: '100%',
               position: 'relative'
           }}>
               {/* Messages container - will take available height minus the input area */}
               <Box sx={{ 
                   flex: 1, 
                   overflowY: 'auto',
                   pt: 1,
                   pb: 2,
                   px: 1
               }}>
                   {posts ? posts.filter(post => !post.isDeleted).map((post, index) => (
                       <div key={post.messageId} id={posts.length-1 === index ? "scrollToThisMessage" : `message-${index}`}>
                           <MessageUi post={post} deleteMessage={deleteMessage} userId={user.sub} />
                       </div>    
                   )) : (
                       <Box sx={{ p: 2 }}>
                           <Typography variant="body2" color="text.secondary">
                               No messages here. Post a public message to start a discussion.
                           </Typography>
                       </Box>
                   )}
               </Box>
               
               {/* Loading indicator */}
               {loader && (
                   <Box sx={{ width: '100%', position: 'absolute', bottom: 80 }}>
                       <LinearProgress />
                   </Box>
               )}
               
               {/* Input area - fixed height at bottom */}
               <Box sx={{ 
                   position: 'sticky', 
                   bottom: 0,
                   width: '100%',
                   bgcolor: 'background.paper',
                   zIndex: 1
               }}>
                   <PostMessageBox 
                       busId={busId} 
                       refreshMessages={refreshMessages} 
                       setLoader={setLoader} 
                   />
               </Box>
           </Box>
       );
   }

   // Original layout with the forum header
   return (
       <div className="forum_bg">
           <div style={{backgroundColor:navCityColor, borderRadius:"10px 10px 0 0", height:"8vh" }} className="navbar-bus">
               <div className="icon-back">
                   <div onClick={() => setShowBusForumPage(false)}>
                       <ArrowBackIcon width={50} htmlColor="#FFFFFF" />
                   </div>
               </div>
               <div className="words">
                   <span>{busInfo} | Public Discussion Room </span>
               </div>
           </div>
       
           <div style={{ height: "75vh", overflow:"auto" }}> 
               <div style={{ margin: "0.5rem"}}>
                   {posts ? posts.filter(post => !post.isDeleted).map((post, index) => (
                       <div id={posts.length-1 === index ? "scrollToThisMessage" : index}>
                           <MessageUi post={post} deleteMessage={deleteMessage} userId={user.sub} />
                       </div>    
                   )) : (
                       <div style={{ margin: "1rem"}}>
                           <Typography variant="body2" color="text.secondary">
                               No messages here. Post a public message to start a discussion.
                           </Typography>
                       </div>
                   )}
               </div> 
           </div>
           <div style={{ height:"2vh"}}> 
               {loader ? <LinearProgress /> : ''}
           </div>  
           <PostMessageBox busId={busId} refreshMessages={refreshMessages} setLoader={setLoader} />
       </div>
   );
}



