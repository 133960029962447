import React from 'react';
import { Typography } from '@mui/material';
import DirectionsBus from '@mui/icons-material/DirectionsBus';
import Refresh from '@mui/icons-material/Refresh';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import './TimelineStyles.css';

// Type options: 'past', 'current', 'future'
export function TimelineItem({ 
  type = 'future', 
  time, 
  name, 
  isDelay, 
  delay,
  refreshCounter = null,
  onRefresh = null,
  loadingRefresh = false
}) {
  // Split time into hours/minutes and AM/PM
  const timeParts = time.split(' ');
  const timeHour = timeParts[0];
  const timePeriod = timeParts[1];
  
  return (
    <div className="timeline-item">
      {/* Timeline node/icon */}
      <div className={`timeline-node ${type}`}>
        {type === 'current' && (
          <DirectionsBus 
            className="bus-icon" 
            sx={{ 
              fontSize: '14px', 
              color: 'white',
              margin: 0,
              padding: 0
            }} 
          />
        )}
      </div>
      
      {/* Current location label for better visibility */}
      {type === 'current' && (
        <div className="current-location-label">
          Current Location
        </div>
      )}
      
      {/* Journey progress indicator */}
      {type === 'past' && (
        <div 
          className="journey-progress" 
          style={{ height: '100%' }}
        ></div>
      )}
      
      {/* No journey progress indicator for current item - this fixes the vertical line issue */}
      {/* {type === 'current' && (
        <div 
          className="journey-progress" 
          style={{ height: '50%' }}
        ></div>
      )} */}
      
      {/* Refresh counter for current item */}
      {type === 'current' && refreshCounter !== null && (
        <div className="refresh-box">
          <span className="refresh-counter">
            {refreshCounter} Sec
          </span>
          <div onClick={onRefresh}>
            <Refresh 
              className={`refresh-icon ${loadingRefresh ? 'spinning' : ''}`} 
            />
          </div>
        </div>
      )}
      
      {/* Card content */}
      <div className={`timeline-content ${type}`}>
        {/* Time */}
        <div className={`time-box ${type}`}>
          <div className="time-hour">{timeHour}</div>
          <div className="time-period">{timePeriod}</div>
        </div>
        
        {/* Stop name and status */}
        <div className="stop-box">
          <div className="stop-name">
            {name}
          </div>
          <div className={`stop-status ${isDelay ? 'delayed' : 'on-time'}`}>
            <span className={`status-dot ${isDelay ? 'delayed' : 'on-time'}`}></span>
            {isDelay ? delay : "On Time"}
          </div>
        </div>
      </div>
      
      {/* Report inaccuracy link for current item */}
      {type === 'current' && (
        <div className="report-link">
          <a href="https://wa.me/918089783756">
            <ReportProblemOutlinedIcon sx={{ fontSize: '0.9rem', mr: 0.5 }} />
            Report inaccuracy
          </a>
        </div>
      )}
    </div>
  );
} 