import * as React from 'react';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, IconButton, Divider, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import { pushGA4CustomEvent } from '../Analytics';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '360px',
  bgcolor: 'background.paper',
  boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
  borderRadius: '12px',
  overflow: 'hidden',
  p: 0,
};

export default function InfoModal(props) {
  
  const showBusStatus = () => {
    props.updateBusStatusPage(true, props.busId, props.busInfo)
    
    let pageTitle = "Onclick Show Bus Status"
    let pageUrl = "onClickShowBusStatus"
    pushGA4CustomEvent(pageTitle,pageUrl)
  }

  const showBusForum = () => {
    props.updateBusForumPage(true, props.busId, props.busInfo)
    props.setForum(true);
  }

  return (
    <Modal
      open={props.open}
      onClose={props.closeInfoModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style} elevation={0}>
        {/* Header with time and close button */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          p: 2,
          pb: 1,
          backgroundColor: 'rgba(93, 74, 125, 0.03)'
        }}>
          <Typography 
            id="modal-modal-title" 
            variant="h6" 
            component="h2"
            sx={{ 
              fontWeight: 600, 
              color: '#333',
              fontSize: '1.25rem',
              lineHeight: 1.2
            }}
          >
            {props.title}
          </Typography>
          <IconButton 
            onClick={props.closeInfoModal}
            size="small"
            sx={{
              color: 'rgba(0, 0, 0, 0.54)',
              transition: 'all 0.2s',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                color: '#5d4a7d'
              }
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        
        {/* Bus route details */}
        <Box sx={{ px: 2.5, py: 2 }}>
          <Typography 
            id="modal-modal-description" 
            variant="body1" 
            sx={{ 
              color: 'text.secondary',
              fontWeight: 500
            }}
          >
            {props.message}
          </Typography>
        </Box>
        
        <Divider sx={{ mx: 2 }} />
        
        {/* Action buttons */}
        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          p: 2.5,
          pt: 2
        }}>
          <Button 
            variant="contained" 
            onClick={showBusStatus} 
            endIcon={<DirectionsBusIcon />}
            sx={{
              flex: 1,
              borderRadius: '8px',
              backgroundColor: '#5d4a7d',
              textTransform: 'none',
              fontSize: '0.875rem',
              fontWeight: 500,
              py: 1,
              boxShadow: '0 1px 3px rgba(93, 74, 125, 0.3)',
              '&:hover': {
                backgroundColor: '#4d3e69',
                boxShadow: '0 2px 5px rgba(93, 74, 125, 0.4)',
              }
            }}
          >
            Bus Status
          </Button>
          <Button 
            variant="outlined"
            onClick={showBusForum} 
            endIcon={<ForumOutlinedIcon />}
            sx={{
              flex: 1,
              borderRadius: '8px',
              color: '#5d4a7d',
              borderColor: '#5d4a7d',
              textTransform: 'none',
              fontSize: '0.875rem',
              fontWeight: 500,
              py: 1,
              '&:hover': {
                backgroundColor: 'rgba(93, 74, 125, 0.04)',
                borderColor: '#4d3e69'
              }
            }}
          >
            Discussion
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}
