import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const InfoBar = ({ routeData }) => {
  // Extract relevant data
  const isLive = routeData?.isLive;
  const busRunningStatus = routeData?.busRunningStatus;
  const currentStop = routeData?.current?.[0]?.name;
  const nextStop = routeData?.future?.[0]?.name;
  const finalStop = routeData?.future?.[routeData?.future?.length - 1]?.name;
  const finalStopTime = routeData?.future?.[routeData?.future?.length - 1]?.time;
  
  // Calculate total number of stops
  const totalStops = 
    (routeData?.past?.length || 0) + 
    (routeData?.current?.length || 0) + 
    (routeData?.future?.length || 0);
  
  // Calculate number of remaining stops
  const remainingStops = (routeData?.future?.length || 0);
  
  const busNotDeparted = busRunningStatus === "NOT_STARTED";
  const busJourneyEnded = busRunningStatus === "ENDED";
  
  return (
    <Box sx={{ 
      mb: 2,
      mx: 2,
      p: 2,
      bgcolor: 'white',
      borderRadius: 2,
      boxShadow: '0 2px 6px rgba(0,0,0,0.06)',
      position: 'relative',
      overflow: 'hidden',
      zIndex: 5
    }}>
      {/* Decorative side accent */}
      <Box sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        width: '4px',
        bgcolor: '#5d4a7d',
        opacity: 0.7
      }} />
      
      {/* Live Indicator */}
      {isLive && !busNotDeparted && !busJourneyEnded && (
        <Chip
          size="small"
          label="LIVE"
          sx={{
            position: 'absolute',
            right: 16,
            top: 14,
            bgcolor: 'rgba(76, 175, 80, 0.1)',
            color: '#2e7d32',
            fontWeight: 600,
            fontSize: '0.6rem',
            height: '20px',
            px: 0.5
          }}
        />
      )}
      
      {busNotDeparted ? (
        /* Bus not departed yet */
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
          <InfoOutlinedIcon sx={{ color: '#5d4a7d', fontSize: '1.2rem', mr: 1.5, opacity: 0.8 }} />
          <Typography variant="body2" sx={{ fontWeight: 500, color: 'text.secondary' }}>
            The bus has not departed from its origin yet
          </Typography>
        </Box>
      ) : busJourneyEnded ? (
        /* Bus journey ended */
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
          <InfoOutlinedIcon sx={{ color: '#5d4a7d', fontSize: '1.2rem', mr: 1.5, opacity: 0.8 }} />
          <Typography variant="body2" sx={{ fontWeight: 500, color: 'text.secondary' }}>
            This bus journey has been completed
          </Typography>
        </Box>
      ) : (
        /* Bus is en route - show details */
        <React.Fragment>
          {/* Current Location */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
            <LocationOnIcon sx={{ color: '#5d4a7d', fontSize: '1rem', mr: 1, opacity: 0.8 }} />
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              Current Location: <Box component="span" sx={{ fontWeight: 400 }}>{currentStop || 'Unknown'}</Box>
            </Typography>
          </Box>
          
          {/* Next Stop Information */}
          {nextStop && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
              <DirectionsBusIcon sx={{ color: '#5d4a7d', fontSize: '1rem', mr: 1, opacity: 0.8 }} />
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                Next Stop: <Box component="span" sx={{ fontWeight: 400 }}>{nextStop}</Box>
              </Typography>
            </Box>
          )}
        </React.Fragment>
      )}
      
      {/* ETA Information - always show if available */}
      {finalStop && finalStopTime && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AccessTimeIcon sx={{ color: '#5d4a7d', fontSize: '1rem', mr: 1, opacity: 0.8 }} />
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            ETA at {finalStop}: <Box component="span" sx={{ fontWeight: 600 }}>{finalStopTime}</Box>
          </Typography>
        </Box>
      )}
      
      {/* Journey Stats */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        mt: 2,
        pt: 2,
        borderTop: '1px solid rgba(0,0,0,0.06)'
      }}>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          Total Stops: {totalStops}
        </Typography>
        
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          Remaining: {remainingStops} stops
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoBar; 