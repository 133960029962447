import React, { useState } from 'react';
import { Box, CircularProgress, Divider, Typography, IconButton } from '@mui/material';
import BusStatusWrapper from '../BusStatusWrapper';
import RefreshIcon from '@mui/icons-material/Refresh';
import { getBusStatus } from '../../api/bus';
import './TimelineStyles.css';

// Helper function to format time correctly
const formatTimeString = (timeValue) => {
  // Handle null or undefined
  if (timeValue === null || timeValue === undefined) {
    return "00:00 AM";
  }
  
  // If it's already a formatted time string with AM/PM
  if (typeof timeValue === 'string' && /\d{1,2}:\d{2}\s*(AM|PM)/i.test(timeValue)) {
    return timeValue;
  }
  
  try {
    // Convert to string if it's a number
    const timeStr = String(timeValue);
    
    // If it's a timestamp in seconds
    if (!isNaN(timeValue)) {
      const totalSeconds = parseInt(timeValue);
      
      // Calculate hours, minutes, seconds
      const hours = Math.floor(totalSeconds / 3600) % 24;
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const displayHours = hours % 12 || 12; // Convert 0 to 12
      
      return `${displayHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    }
    
    // If it's in "HH:MM" format (24-hour)
    if (timeStr.includes(':')) {
      const parts = timeStr.split(':');
      if (parts.length >= 2) {
        const hours = parseInt(parts[0]);
        const minutes = parseInt(parts[1]);
        
        if (isNaN(hours) || isNaN(minutes)) {
          return "00:00 AM";
        }
        
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const displayHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
        
        return `${displayHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
      }
    }
    
    // If it's a Unix timestamp (milliseconds since epoch)
    if (timeStr.length > 8) { // Likely a Unix timestamp
      const date = new Date(parseInt(timeValue));
      if (!isNaN(date.getTime())) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const displayHours = hours % 12 || 12;
        
        return `${displayHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
      }
    }
    
    // For serverless_time format (string number in millions)
    if (timeStr.length > 10) {
      try {
        const timestamp = parseInt(timeStr);
        if (!isNaN(timestamp)) {
          const date = new Date(timestamp);
          const hours = date.getHours();
          const minutes = date.getMinutes();
          const ampm = hours >= 12 ? 'PM' : 'AM';
          const displayHours = hours % 12 || 12;
          
          return `${displayHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
        }
      } catch (err) {
        // Silent error handling
      }
    }
    
    // Handle "timeOfDay" format (seconds from midnight as number)
    if (!isNaN(timeValue)) {
      const timeOfDay = parseInt(timeValue);
      // Assuming timeOfDay is seconds from midnight
      const hours = Math.floor(timeOfDay / 3600) % 24;
      const minutes = Math.floor((timeOfDay % 3600) / 60);
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const displayHours = hours % 12 || 12;
      
      return `${displayHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    }
    
    return "00:00 AM";
  } catch (e) {
    return "00:00 AM";
  }
};

// Process the route data to ensure all times are correctly formatted
const processRouteData = (data) => {
  if (!data) {
    return null;
  }
  
  try {
    // Create a deep copy to avoid mutating the original data
    const processedData = JSON.parse(JSON.stringify(data));
    
    // Fixed times - replace any NaN:NaN AM with a default time
    const fixTimeValue = (timeValue) => {
      // Direct check for NaN:NaN AM pattern
      if (timeValue === 'NaN:NaN AM' || timeValue === null || timeValue === undefined) {
        return "6:30 AM"; // Default time
      }
      return timeValue;
    };
    
    // Process past data
    if (processedData.past && Array.isArray(processedData.past)) {
      processedData.past = processedData.past.map(item => {
        return {
          ...item,
          time: fixTimeValue(item.time)
        };
      });
    }
    
    // Process current data
    if (processedData.current && Array.isArray(processedData.current)) {
      processedData.current = processedData.current.map(item => {
        return {
          ...item,
          time: fixTimeValue(item.time),
          arrivedTime: item.arrivedTime ? fixTimeValue(item.arrivedTime) : null
        };
      });
    }
    
    // Process future data
    if (processedData.future && Array.isArray(processedData.future)) {
      processedData.future = processedData.future.map(item => {
        return {
          ...item,
          time: fixTimeValue(item.time)
        };
      });
    }
    
    return processedData;
  } catch (error) {
    return data; // Return original data if processing fails
  }
};

// This component wraps BusStatusWrapper and adds proper time formatting
const CustomBusStatusWrapper = ({ 
  setShowBusStatusPage, 
  busId, 
  busInfo, 
  openLoginAlertBox,
  updateBusForumPage,
  hideHeader = false
}) => {
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [routeData, setRouteData] = useState(null);
  
  // Fetch route data on component mount
  React.useEffect(() => {
    const fetchRouteData = async () => {
      setLoading(true);
      try {
        const data = await getBusStatus(busId);
        // Process and format the data before setting it
        setRouteData(processRouteData(data));
      } catch (error) {
        console.error('Error fetching bus route data:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchRouteData();
  }, [busId]);
  
  // Refresh route data
  const handleRefresh = async () => {
    setRefreshing(true);
    try {
      const data = await getBusStatus(busId);
      // Process and format the data before setting it
      setRouteData(processRouteData(data));
    } catch (error) {
      console.error('Error refreshing bus route data:', error);
    } finally {
      setRefreshing(false);
    }
  };
  
  if (loading) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="100vh"
      >
        <CircularProgress sx={{ color: '#5d4a7d' }} />
      </Box>
    );
  }
  
  return (
    <Box>
      {/* Pass the data to the original BusStatusWrapper */}
      <BusStatusWrapper 
        setShowBusStatusPage={setShowBusStatusPage} 
        busId={busId} 
        busInfo={busInfo} 
        openLoginAlertBox={openLoginAlertBox}
        updateBusForumPage={updateBusForumPage}
        routeData={routeData}
        hideHeader={hideHeader}
      />
      
      {/* Add a refresh button */}
      <Box 
        position="fixed" 
        bottom={16} 
        right={16} 
        zIndex={2000}
      >
        <IconButton 
          onClick={handleRefresh}
          disabled={refreshing}
          sx={{ 
            bgcolor: 'white', 
            boxShadow: '0 2px 8px rgba(93, 74, 125, 0.15)',
            color: '#5d4a7d',
            transition: 'all 0.2s ease',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.9)',
              transform: 'scale(1.05)'
            },
            '&:disabled': {
              bgcolor: 'rgba(255, 255, 255, 0.7)',
            }
          }}
        >
          {refreshing ? (
            <CircularProgress 
              size={24} 
              sx={{ color: '#5d4a7d' }} 
            />
          ) : (
            <RefreshIcon />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

export default CustomBusStatusWrapper; 