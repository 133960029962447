import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { getBusStops, getBusTimeTable, likeBusPostById, disLikeBusPostById, getBusLiveList, getRouteDirections } from '../api/bus'
import BusTimeTable from './busTimeTable'
import AlertDialog from './AlertDialog'
import { isLoggedIn } from '../api/account';
import BusReviewForm from './busReviewForm';
import BusTimePosts from './busTimePosts';
import BusLiveList from './BusLiveList';
import { navigateToLoginScreen } from '../pages/PageProfile';
import BusDirectionToggleButton from './BusDirectionToggleButton';
import BusRouteFilterToggleButton from './BusRouteFilterToggleButton';
import TuneIcon from '@mui/icons-material/Tune';
import { CircularProgress, IconButton, LinearProgress } from '@mui/material';
import { Backdrop } from '@material-ui/core';
import { pushGA4CustomEvent } from '../Analytics';
import { createTheme } from '@mui/material/styles';
import BusStatusWrapper from './BusStatusWrapper';
import Forum  from './forum/Forum';

function CardBus (props) {
    const theme = createTheme({
      palette: {
        themePurple: {
          main: '#800080',
          darker: '#3C005A',
        },
      },
    });

    const [refreshBusTable, setRefreshBusTable] = useState(1)
    const [isDisplayContent, setIsDisplayContent] = useState(false)
    const [selectedBusStop, setSelectedBusStop] = useState(null)
    const [busStops, setBusStops] = useState(null)
    const [isBusStopMenuOpen, setIsBusStopMenuOpen] = useState(false)
    const [showBusRouteFilter, setShowBusRouteFilter] = useState(false)

    const [selectedBusDirection, setSelectedBusDirection] = useState()
    const [routeDirections, setRouteDirections] = useState([])
    const [busRouteFilter, setBusRouteFilter] = useState(null)

    const [selectedBusRoute, setSelectedBusRoute] = useState(null)
    const [busRoutes, setBusRoutes] = useState(null)

    const [busTimeTable, setBusTimeTable] = useState(null)
    const [filteredBusTimeTable, setFilteredBusTimeTable] = useState(null)
    const [busFilters, setBusFilters] = useState(null)
    const [busReviewForm, setBusReviewForm] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const [busReviewProps, setBusReviewProps] = useState({ title: "Add your feedback here." })
    const [selectedRow, setSelectedRow] = useState(-1)

    const [busTimeLoadingProgressBar, setBusTimeLoadingProgressBar] = React.useState(false)

    let loginAlertMessage = 'Only logged-in users can access all features.'

    const [showBusStatusPage, setShowBusStatusPage] = useState(false)
    const [busStatusBusId, setBusStatusBusId] = useState(null)
    const [busStatusBusInfo, setBusStatusBusInfo] = useState(null)
    const [forum ,setForum] = useState(false)
    const [busLiveList, setBusLiveList] = useState(null)
    const [showBusForumPage, setShowBusForumPage] = useState(false)
    const [busForumBusId,setBusForumBusId] = useState(null)
    const [ busForumBusInfo,setBusForumBusInfo] = useState(null)

    const updateBusStatusPage = (showBusStatus, busId, busInfo) => {
      setShowBusStatusPage(showBusStatus)
      setBusStatusBusId(busId)
      setBusStatusBusInfo(busInfo)
    }

    const updateBusForumPage = (showBusForum, busId,busInfo) => {
      setShowBusForumPage(showBusForum)
      setBusForumBusId(busId)
      setBusForumBusInfo(busInfo)
    }

    useEffect(() => {
        props.displayFullScreen(!!showBusStatusPage || !!showBusForumPage)
      }, [showBusStatusPage, showBusForumPage, props])


    const displayContent = () => {
        setIsDisplayContent(!isDisplayContent)
    }

    const handleBusStopChange = selectedOption => {

    let pageTitle = "Bus Stop selected"
    let pageUrl =  "BusStop"
    let eventDetails =  `Bus stop ${selectedOption.label} `
    pushGA4CustomEvent(pageTitle,pageUrl,eventDetails)
    setSelectedBusStop(selectedOption)
    }

    const busStopFocused = () => {
      if(busStops) setIsBusStopMenuOpen(true)
    }

    const handleBusRouteChange = selectedOption => {
      setSelectedBusRoute(selectedOption)
    }

    const handleRefreshBusTimeTable = () => {
      setRefreshBusTable(refreshBusTable => (refreshBusTable+1))
    }

    const handleBusDirectionChange = (direction) => {
      setSelectedBusDirection(direction)
    }

    const handleBusRouteFilterClicked = (value) => {
      let pageTitle = "filter icon click"
      let pageUrl =  "filterclick"
      pushGA4CustomEvent(pageTitle,pageUrl)
      setShowBusRouteFilter(!showBusRouteFilter)
    }

    const handleBusRouteFilterChange = (filter) => {
      setBusRouteFilter(filter)
      if(filter=== 'All') {
        const allBusTimes = extractAllBusTimes(busTimeTable.busTimeTable)
        setFilteredBusTimeTable(allBusTimes)
      } else {
        const filteredBusTime = extractFilteredBusTimes(busTimeTable.busTimeTable, filter)
        setFilteredBusTimeTable(filteredBusTime)
      }
    }

    const busDirectionToggleButtonProps = {
      handleBusDirectionChange,
      theme
    }

    const reviewBusTime = (row) => {
      console.log(`selectedBusStop ${JSON.stringify(selectedBusStop)}`)
      console.log(`selectedBusRoute ${JSON.stringify(selectedBusRoute)}`)
      console.log(row)
      const user = isLoggedIn()
      if(!user) {
        console.log('set alert dialog to true')
        setOpenAlert(true)
      } else {
        setBusReviewProps({ 
          title: `Bus: ${row.values.busName}
                  Time: ${row.values.busTime} `,
          busStop: selectedBusStop.value,
          busRoute: selectedBusRoute.value,
          busName: row.values.busName,
          busTime: row.values.busTime,
        })
        setSelectedRow(row.index)
        setBusReviewForm(true)
      }
    }

    const likeBusUserPost = async (postId, postIndex) => {
      const user = isLoggedIn()
      if(!user) {
        console.log('set alert dialog to true')
        setOpenAlert(true)
      } else {
        await likeBusPostById(postId, postIndex, {
          busStop: selectedBusStop.value,
          busDirection: selectedBusDirection,
        })
        handleRefreshBusTimeTable()
      }
    }

    const disLikeBusUserPost = async (postId, postIndex) => {
      const user = isLoggedIn()
      if(!user) {
        console.log('set alert dialog to true')
        setOpenAlert(true)
      } else {
        await disLikeBusPostById(postId, postIndex, {
          busStop: selectedBusStop.value,
          busDirection: selectedBusDirection,
        })
        handleRefreshBusTimeTable()
      }
    }

    const openLoginAlertBox = () => {
      setOpenAlert(true)
    }

    const closeAlertBox = () => {
      setOpenAlert(false)
    };

    useEffect(() => {
      async function populateBusLiveList() {
        const busLiveList = await getBusLiveList()
        if(busLiveList) setBusLiveList(busLiveList)
      }
      if(props.displayBus) {
        setIsDisplayContent(true)
        populateBusLiveList()
      }
    }, [props.displayBus])

    useEffect(() => {
      async function populateBusStopsData() {
        const busStopsData = await getBusStops()
        if(busStopsData) setBusStops(busStopsData)
      }
      if (isDisplayContent && !busStops) populateBusStopsData()
    }, [busStops, isDisplayContent])

    useEffect(() => {
      async function populateBusDirection() {
        setBusTimeLoadingProgressBar(true)
        const routeDirectionsData = await getRouteDirections(selectedBusStop.value)
        if(routeDirectionsData?.length) {
          setRouteDirections(routeDirectionsData)
          setSelectedBusDirection(routeDirectionsData[0])
        } 
        setBusTimeLoadingProgressBar(false)
      }
      if (isDisplayContent && selectedBusStop) populateBusDirection()
    }, [isDisplayContent, selectedBusStop])

    useEffect(() => {
      async function populateBusTimeTableData(selectedBusStop, selectedBusDirection) {
        setBusTimeLoadingProgressBar(true)
        const busTimeTable = await getBusTimeTable(selectedBusStop.value, selectedBusDirection)
        if(busTimeTable) setBusTimeTable(busTimeTable)
        const filters = Object.keys(busTimeTable.busTimeTable)
        console.log('filter', filters)
        if(filters && filters?.length > 1) {
          setBusFilters(['All', ...filters])
        } else {
          setBusFilters(null)
        }
        const allBusTimes = extractAllBusTimes(busTimeTable.busTimeTable);
        console.log("allBusTimes " , allBusTimes)
        setFilteredBusTimeTable(allBusTimes)
        setBusTimeLoadingProgressBar(false)
      }
      setIsBusStopMenuOpen(false)
      if (isDisplayContent && selectedBusStop && selectedBusDirection) populateBusTimeTableData(selectedBusStop, selectedBusDirection)
    }, [isDisplayContent, selectedBusStop, selectedBusDirection, refreshBusTable])

    const user = isLoggedIn()

    if(showBusStatusPage ) {
      return (
        (<div>
          <BusStatusWrapper setShowBusStatusPage={setShowBusStatusPage} busId={busStatusBusId} busInfo={busStatusBusInfo} openLoginAlertBox={openLoginAlertBox} updateBusForumPage={updateBusForumPage}/>
          <AlertDialog open={openAlert} closeAlertBox={closeAlertBox} loginAlertMessage={loginAlertMessage} />
        </div>)
      )
    } else if (showBusForumPage){
      console.log(busForumBusId)
      return (
        (<div>
          <Forum busId={busForumBusId} setBusForumBusId={setBusForumBusId} setShowBusForumPage={setShowBusForumPage} busInfo={busForumBusInfo}/>
        </div>)
      )
    }
    else if(isDisplayContent) {
        return (
          <div className="busCardWrapper">
           { busLiveList && busLiveList.length ?
              <div className='card'>
                <div className='card__body'>
                  <div className='card__bus_status'>
                    <div className='card__bus_status__title'>Live Bus</div>
                    {busLiveList && busLiveList.length ? <BusLiveList busLiveList={busLiveList} updateBusStatusPage={updateBusStatusPage}/> : (
                      <div className="card__bus_status__no_liveList_label" >waiting for live bus data ... </div>
                    )}
                  </div> 
                </div>
              </div>
              : ''
            }  
            <div className='card'>
              <div className='card__body'>
                <div className='card__bus_content' style={{ backgroundImage: busTimeTable?.busTimePosts ? "" : "url(undraw_bus_stop.svg)" }}>
                  
                  { busStops ? 
                        <>
                        {!selectedBusStop ? (<div className='card__bus_content__question_label'>
                        To get the Bus timetable, please select the bus stop.
                        </div>) : '' }
                        <div className='card__bus_content__input'>
                          <Select placeholder='Bus stop' maxMenuHeight={'19rem'} isSearchable={false}
                          value={selectedBusStop} onChange={handleBusStopChange} options={busStops} 
                          menuIsOpen={isBusStopMenuOpen} onFocus={busStopFocused}/>
                        </div>
                        </>
                  : (<div><LinearProgress /></div>)  }
                  
                  {/* <div className='card__bus_content__input'>
                    {selectedBusStop ? <Select placeholder='Search Route' maxMenuHeight={180}
                    value={selectedBusRoute} onChange={handleBusRouteChange} options={busRoutes} /> : ''}
                  </div> */}
                  {selectedBusStop && selectedBusDirection ? 
                  <div className='card__bus_content__direction' >
                    <div className='card__bus_content__center_label' >Towards</div>
                    <div className='scrollbox card__bus_content__toggle'>
                      <BusDirectionToggleButton {...busDirectionToggleButtonProps} routeDirections={routeDirections} selectedBusDirection={selectedBusDirection} /> 
                    </div>
                  </div> 
                  : ''}
                    {busFilters ? 
                    showBusRouteFilter ?
                      (
                      <div className='card__bus_content__to_filter' >
                        <div className='card__bus_content__center_label' >To</div>
                        <div className='card__bus_content__toggle_filter'>
                          <BusRouteFilterToggleButton filters={busFilters} handleBusRouteFilterChange={handleBusRouteFilterChange} theme={theme} /> 
                        </div>
                      </div>)
                      : 
                      (
                      <div className='card__bus_content__filter_icon'>
                      <IconButton className='filter_blink' aria-label="delete" size="small" onClick={() => handleBusRouteFilterClicked()}>
                       <TuneIcon style={{ marginRight:'0.5rem' }} /> filter 
                      </IconButton>
                      </div>
                      )
                    : ''}
                  <div className='card__table'>
                  { busTimeLoadingProgressBar ? (<div><LinearProgress /></div>) : '' }
                    { filteredBusTimeTable ? <BusTimeTable rows={filteredBusTimeTable} selectedRow={selectedRow}
                      reviewBusTime={reviewBusTime} updateBusStatusPage={updateBusStatusPage} busLiveList={busLiveList} 
                      setForum={setForum} updateBusForumPage={updateBusForumPage} 
                      handleRefreshBusTimeTable={handleRefreshBusTimeTable} 
                      openLoginAlertBox={openLoginAlertBox}
                      busStop={selectedBusStop?.value}
                      busDirection={selectedBusDirection}
                    /> : '' }
                  </div>
                </div> 
              </div>
            </div>
            {/* {busTimeTable?.busTimePosts ? 
              (user ? (<div className='card'>
              <div className='card__body'>
                <div className='card__title'>{"Bus time posted by users"}</div>
                <BusReviewForm  busStop={selectedBusStop.value} busDirection={selectedBusDirection}
                handleRefreshBusTimeTable={handleRefreshBusTimeTable} />
                
                <div className='card__bus_time_posts_table' style={{ backgroundImage: "url(undraw_bus_stop.svg)" }}>
                  <div className='card__table'>
                    { busTimeTable ? <BusTimePosts rows={busTimeTable.busTimePosts} likeBusUserPost={likeBusUserPost} disLikeBusUserPost={disLikeBusUserPost} /> : '' }
                  </div>
                </div> 
              </div>
            </div>) : 
              ViewPostLoginCard(props)
              ) 
              : ''
            } */}
            <AlertDialog open={openAlert} closeAlertBox={closeAlertBox} loginAlertMessage={loginAlertMessage} />
          </div>
        )
    } else {
        return (
          <div className='card' onClick={displayContent} >
            <div className='card__body'>
              <img className='card__image' alt='' src={props.img} />
              <div className='card__title'>{props.title}</div>
              <div className='card__description'>{props.description}</div>
            </div>
            <button className='card__btn'>View</button>
          </div>
        )
    }
}

export const ViewPostLoginCard = (props) => {
  return ( 
    <div className='card' onClick={() => navigateToLoginScreen()} >
      <div className='card__body'>
        <div className='card__title'>{'Bus Time Posted by Users'}</div>
        <div className='card__description'>{'View and add new Bus timings.'}</div>
      </div>
      <button className='card__btn'>login to view</button>
    </div>
    ) 
}

function extractAllBusTimes(busTimeTable) {
  const filters = Object.keys(busTimeTable)
  const allBusTimes = filters.reduce((all, filter) => {
    return all.concat(busTimeTable[filter]);
  }, []);
  allBusTimes.sort((previous, next) => {
    return (previous.rawTime - next.rawTime);
  });
  return allBusTimes;
}

function extractFilteredBusTimes(busTimeTable, filter) {
  const filteredBusTime = busTimeTable[filter]
  filteredBusTime.sort((previous, next) => {
    return (previous.rawTime - next.rawTime);
  });
  return filteredBusTime;
}

export default CardBus;
