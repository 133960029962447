import axios from 'axios'
import { place, SERVICE_API_URL } from '../config/constant'

export const getBusStops = async () => {
    const response = await axios.get(SERVICE_API_URL+'/bus/stops', { params: {
        place
    }});
    return response.data
}

export const getRouteDirections = async (busStop) => {
    const response = await axios.get(SERVICE_API_URL+'/bus/directions', { params: {
        busStop, place
    }});
    return response.data
}

export const getBusRoutes = async (busStop) => {
    const response = await axios.get(SERVICE_API_URL+'/bus/routes', { params: {
        busStop, place
    }});
    return response.data
}

export const getBusTimeTable = async (busStop, busDirection) => {
    const response = await axios.get(SERVICE_API_URL+'/bus/timeTable', { params: {
        busStop, busDirection, place,
    }});
    return response.data
}

export const addBusFeedback = async (feedbackFor, feedback) => {
    try {
        const response = await axios.post(SERVICE_API_URL+'/bus/feedback', { 
            feedbackFor,
            feedback
        });
        return response.data
    } catch (e) {
        console.error(e)
        return null
    }
}

export const likeBusPostById = async (postId, postIndex, feedbackFor) => {
    try {
        const response = await axios.post(SERVICE_API_URL+'/bus/post/like', { 
            postId: postId,
            postIndex,
            feedbackFor
        });
        return response.data
    } catch (e) {
        console.error(e)
        return null
    }
}

export const disLikeBusPostById = async (postId, postIndex, feedbackFor) => {
    try {
        const response = await axios.post(SERVICE_API_URL+'/bus/post/dislike', { 
            postId: postId,
            postIndex,
            feedbackFor
        });
        return response.data
    } catch (e) {
        console.error(e)
        return null
    }
}

export const getBusStatus = async (busId) => {
    const response = await axios.get(SERVICE_API_URL+'/bus/status', { params: {
        busId, place
    }});
    
    return response.data;
}

export async function sendBusLiveGeo(busId, lat, lng) {
    console.log(busId)
    console.log({ lat: lat, lng: lng });
    try {
        const response = await axios.post(SERVICE_API_URL+'/bus/busLiveGeo', { 
            place,
            busId,
            geoLocation: {
                latitude: lat,
                longitude: lng
            }
        });
        return response.data
    } catch (e) {
        console.error(e)
        return null
    }
}

export const getBusLiveList = async () => {
    const response = await axios.get(SERVICE_API_URL+'/bus/liveList', { params: {
        place
    }});
    return response.data
}

export const adminUploadRouteGPS = async (data) => {
    const response = await axios.post(SERVICE_API_URL+'/bus/adminUploadRouteGPS', {
        place, data
    });
    return response.data
}

export const adminUploadBusStopGPS = async (data) => {
    const response = await axios.post(SERVICE_API_URL+'/bus/adminUploadBusStopGPS', {
        place, data
    });
    return response.data
}

export const busGPSRewardVerify = async () => {
    const response = await axios.post(SERVICE_API_URL+'/bus/busGPSRewardVerify', {
        place
    });
    return response.data
}

export const getBusForumMessage = async(busId) => {
    try {
    const response = await axios.get(SERVICE_API_URL+ '/bus/forum',{params: {
       busId
    }})
    return response.data
    } catch (e) {
    console.error(e)
    } 

}


export const postBusForumMessage = async(busId,message, name) => {
    try {
    const response = axios.post( SERVICE_API_URL+'/bus/forum',{
        busId, message, name
    })
    return response.data
    } catch (e) {
    console.error(e)
    } 
}

export const deleteForumMessage = async(busId,messageId) => {
    try {
       const response = axios.post(SERVICE_API_URL+'/bus/deleteMessage',{
            busId , messageId
        })
    return response.data
    }catch(e){
        console.error(e)
    }
}

export const updateBusTime = async(updateData) => {
    try {
        console.log('updateData', updateData)
        // If this is a delete operation, add a log for clarity
        if (updateData.delete) {
            console.log('Deleting bus time:', updateData);
        }
        
        const response = await axios.post(SERVICE_API_URL+'/bus/updateTime', {
            ...updateData,
            place
        });
        return response.data;
    } catch(e) {
        console.error('Error updating/deleting bus time:', e);
        return null;
    }
}

export const searchBusRoutes = async (startStop, endStop) => {
    try {
        const response = await axios.get(SERVICE_API_URL+'/bus/routeSearch', { 
            params: {
                startStop, 
                endStop,
                place
            }
        });
        return response.data;
    } catch (e) {
        console.error('Error searching bus routes:', e);
        throw e;
    }
}