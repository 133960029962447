import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Paper, 
  Box, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Button, 
  CircularProgress,
  Alert,
  Divider,
  Chip,
  useTheme,
  useMediaQuery,
  Card,
  CardContent
} from '@mui/material';
import { SwapVert, DirectionsBus, Navigation as NavigationIcon, AccessTime } from '@mui/icons-material';
import { getBusStops, searchBusRoutes, getBusLiveList } from '../../api/bus';
import InfoModal from '../InfoModel';

const BusRouteSearch = ({ 
  updateBottomNavigationTab, 
  displayFullScreen,
  updateBusStatusPage,
  updateBusForumPage,
  setForum,
  savedSearchParams = { startStop: '', endStop: '', hasSearched: false },
  saveSearchParams
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [stops, setStops] = useState([]);
  const [startStop, setStartStop] = useState(savedSearchParams.startStop || '');
  const [endStop, setEndStop] = useState(savedSearchParams.endStop || '');
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [busResults, setBusResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [searched, setSearched] = useState(savedSearchParams.hasSearched || false);
  const [busLiveList, setBusLiveList] = useState([]);
  const [infoModalProps, setInfoModalProps] = useState({open: false});
  
  // Fetch all bus stops and bus live list on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [stopsData, liveListData] = await Promise.all([
          getBusStops(),
          getBusLiveList()
        ]);
        
        setStops(stopsData);
        setBusLiveList(liveListData || []);
        setInitialLoading(false);
        
        // If we have saved search parameters with hasSearched=true, perform the search
        if (savedSearchParams.hasSearched && savedSearchParams.startStop && savedSearchParams.endStop) {
          performSearch(savedSearchParams.startStop, savedSearchParams.endStop);
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setErrorMessage('Unable to load data. Please try again later.');
        setInitialLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleStartStopChange = (event) => {
    setStartStop(event.target.value);
    if (saveSearchParams) {
      saveSearchParams({
        ...savedSearchParams,
        startStop: event.target.value,
        endStop: endStop
      });
    }
  };

  const handleEndStopChange = (event) => {
    setEndStop(event.target.value);
    if (saveSearchParams) {
      saveSearchParams({
        ...savedSearchParams,
        startStop: startStop,
        endStop: event.target.value
      });
    }
  };

  const handleSwapStops = () => {
    const temp = startStop;
    setStartStop(endStop);
    setEndStop(temp);
    if (saveSearchParams) {
      saveSearchParams({
        ...savedSearchParams,
        startStop: endStop,
        endStop: startStop
      });
    }
  };

  // Helper function to perform the search
  const performSearch = async (start, end) => {
    setLoading(true);
    setErrorMessage('');
    setBusResults([]);
    
    try {
      const results = await searchBusRoutes(start, end);
      setBusResults(results);
      setSearched(true);
      
      if (results.length === 0) {
        setErrorMessage('No direct buses found between these stops');
      }
      
      // Save the search parameters
      if (saveSearchParams) {
        saveSearchParams({
          startStop: start,
          endStop: end,
          hasSearched: true
        });
      }
    } catch (error) {
      console.error('Error searching bus routes:', error);
      setErrorMessage('Unable to search for bus routes. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    if (!startStop || !endStop) {
      setErrorMessage('Please select both start and destination bus stops');
      return;
    }

    if (startStop === endStop) {
      setErrorMessage('Start and destination stops cannot be the same');
      return;
    }

    performSearch(startStop, endStop);
  };

  // Format time (seconds from midnight) to AM/PM format
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const displayHours = hours % 12 || 12;
    
    return `${displayHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  };

  // Calculate the journey duration in minutes
  const calculateDuration = (startTime, endTime) => {
    const durationInSeconds = endTime - startTime;
    return Math.floor(durationInSeconds / 60);
  };

  // For the live button and info modal functionality
  const showModal = (bus) => {
    const busTime = formatTime(bus.departureTime);
    const busName = bus.busName;
    const routeName = bus.routeName;
    
    // Create the complete busId using routeId_departureTime format
    // Make sure departureTime is a number, not a string
    const departureTime = typeof bus.departureTime === 'string' 
      ? parseInt(bus.departureTime, 10) 
      : bus.departureTime;
      
    // Create the composite ID with underscore separator
    const busId = `${bus.routeId}_${departureTime}`;

    setInfoModalProps({
      title: `${busTime}  |  ${busName}`,
      message: `${routeName}`,
      open: true,
      closeInfoModal,
      updateBusStatusPage,
      updateBusForumPage,
      setForum,
      busId: busId,
      busInfo: `${busName} (${routeName})`
    });
  };

  const closeInfoModal = () => {
    setInfoModalProps({
      open: false
    });
  };

  const openLoginAlertBox = () => {
    // This function would be used for login alerts
    // Not implementing this since we're assuming the user is logged in
  };

  if (initialLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', maxWidth: '100%', overflowX: 'hidden' }}>
      <Paper elevation={1} sx={{ 
        p: isMobile ? 2 : 3, 
        borderRadius: 2, 
        mx: 'auto',
        maxWidth: '100%',
        background: '#fcfcfc'
      }}>
        <Typography 
          variant="h5" 
          component="h1" 
          gutterBottom 
          align="center"
          sx={{
            fontWeight: 500,
            color: '#5d4a7d',
            mt: 1,
            mb: 0.5
          }}
        >
          Bus Search
        </Typography>
        <Typography 
          variant="body1" 
          color="text.secondary" 
          paragraph 
          align="center"
          sx={{ mb: 3 }}
        >
          Find buses between any two stops.
        </Typography>

        <Box sx={{ 
          mt: 1, 
          mb: 4,
          backgroundColor: '#fff',
          borderRadius: 2,
          p: 2,
          boxShadow: '0 1px 2px rgba(0,0,0,0.04)'
        }}>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'stretch' : 'center', 
              gap: 2
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="start-stop-label">From</InputLabel>
              <Select
                labelId="start-stop-label"
                id="start-stop"
                value={startStop}
                label="From"
                onChange={handleStartStopChange}
                sx={{
                  borderRadius: 1,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.15)'
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    sx: { maxHeight: 300 }
                  }
                }}
              >
                {stops.map((stop) => (
                  <MenuItem key={stop.value} value={stop.value}>
                    {stop.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                mx: isMobile ? 0 : 1 
              }}
            >
              <Button 
                onClick={handleSwapStops}
                sx={{ 
                  minWidth: 50,
                  width: isMobile ? '100%' : '50px',
                  height: isMobile ? '45px' : '50px',
                  borderRadius: '8px',
                  backgroundColor: 'rgba(93, 74, 125, 0.06)',
                  color: '#5d4a7d',
                  '&:hover': {
                    backgroundColor: 'rgba(93, 74, 125, 0.12)',
                  }
                }}
              >
                <SwapVert />
              </Button>
            </Box>

            <FormControl fullWidth>
              <InputLabel id="end-stop-label">To</InputLabel>
              <Select
                labelId="end-stop-label"
                id="end-stop"
                value={endStop}
                label="To"
                onChange={handleEndStopChange}
                sx={{
                  borderRadius: 1,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.15)'
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    sx: { maxHeight: 300 }
                  }
                }}
              >
                {stops.map((stop) => (
                  <MenuItem key={stop.value} value={stop.value}>
                    {stop.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box mt={3} display="flex" justifyContent="center">
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleSearch}
              disabled={loading || !startStop || !endStop || startStop === endStop}
              sx={{ 
                px: 4, 
                py: 1.2,
                borderRadius: '8px',
                backgroundColor: '#5d4a7d',
                boxShadow: '0 1px 3px rgba(93, 74, 125, 0.3)',
                fontSize: '0.9rem',
                fontWeight: 500,
                textTransform: 'uppercase',
                letterSpacing: '0.5px',
                '&:hover': {
                  backgroundColor: '#4d3e69'
                },
                minWidth: '180px'
              }}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: '#fff' }} />
              ) : (
                'SEARCH BUSES'
              )}
            </Button>
          </Box>
        </Box>

        {errorMessage && (
          <Box mt={2} mb={2} sx={{ borderRadius: 2, overflow: 'hidden' }}>
            <Alert 
              severity="warning"
              sx={{
                borderRadius: 2,
                '& .MuiAlert-icon': {
                  color: '#f57c00'
                }
              }}
            >
              {errorMessage}
            </Alert>
          </Box>
        )}

        {searched && busResults.length > 0 && (
          <>
            <Divider sx={{ my: 3 }}>
              <Chip 
                label={`${busResults.length} Bus${busResults.length > 1 ? 'es' : ''} Found`}
                color="primary"
                sx={{ 
                  fontWeight: 500,
                  px: 2,
                  py: 0.5,
                  borderRadius: '16px',
                  backgroundColor: 'rgba(93, 74, 125, 0.08)',
                  color: '#5d4a7d',
                  border: '1px solid rgba(93, 74, 125, 0.3)',
                  '& .MuiChip-label': {
                    px: 1
                  }
                }}
              />
            </Divider>
            
            <Box sx={{ 
              maxWidth: '100%', 
              overflowX: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}>
              {/* Card-based layout for search results */}
              {busResults.map((bus, index) => {
                const isLive = busLiveList.find((busLive) => busLive.busId === bus.routeId);
                const iconColor = isLive ? "#4caf50" : "#9e9e9e";
                const duration = calculateDuration(bus.departureTime, bus.arrivalTime);
                
                return (
                  <Card 
                    key={index} 
                    elevation={0} 
                    onClick={() => showModal(bus)}
                    sx={{ 
                      cursor: 'pointer',
                      '&:hover': {
                        boxShadow: '0 2px 8px rgba(0,0,0,0.09)',
                        transform: 'translateY(-1px)',
                        transition: 'all 0.2s ease'
                      },
                      borderRadius: 2,
                      overflow: 'hidden',
                      border: '1px solid rgba(0,0,0,0.08)',
                      position: 'relative',
                      transition: 'all 0.2s ease'
                    }}
                  >
                    {/* Colored accent based on live status */}
                    <Box sx={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      bottom: 0,
                      width: '4px',
                      backgroundColor: isLive ? 'rgba(76, 175, 80, 0.7)' : 'rgba(158, 158, 158, 0.3)'
                    }} />
                    
                    <CardContent sx={{ p: 2, '&:last-child': { pb: 2 }, pl: 3 }}>
                      {/* Bus name and route with improved layout */}
                      <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 2,
                        pr: 1
                      }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '50%',
                            backgroundColor: 'rgba(93, 74, 125, 0.08)',
                            p: 1,
                            mr: 2
                          }}>
                            <DirectionsBus sx={{ color: '#5d4a7d' }} />
                          </Box>
                          <Box>
                            <Typography variant="subtitle1" sx={{ fontWeight: 600, lineHeight: 1.2, color: '#333' }}>
                              {bus.busName}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.2 }}>
                              {bus.routeName}
                            </Typography>
                          </Box>
                        </Box>
                        
                        {/* Live indicator moved to right side */}
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: isLive ? 'rgba(76, 175, 80, 0.1)' : 'transparent',
                          borderRadius: '16px',
                          px: isLive ? 1.5 : 0,
                          py: isLive ? 0.5 : 0
                        }}>
                          <NavigationIcon fontSize="small" sx={{ color: iconColor, mr: isLive ? 0.5 : 0 }} />
                          {isLive && (
                            <Typography variant="body2" sx={{ 
                              color: '#2e7d32', 
                              fontWeight: 500,
                              fontSize: '0.75rem'
                            }}>
                              LIVE
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      {/* Time information with cleaner layout */}
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        mt: 1.5
                      }}>
                        {/* Departure time */}
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="caption" color="text.secondary" sx={{ fontWeight: 500, fontSize: '0.7rem', letterSpacing: '0.5px' }}>
                            DEPARTURE
                          </Typography>
                          <Typography variant="h6" sx={{ 
                            fontWeight: 500, 
                            color: '#5d4a7d', 
                            lineHeight: 1.2
                          }}>
                            {formatTime(bus.departureTime)}
                          </Typography>
                        </Box>

                        {/* Journey duration in middle */}
                        <Box sx={{ 
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          px: 2
                        }}>
                          <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            borderRadius: '16px',
                            px: 1.5,
                            py: 0.5,
                            mb: 0.5
                          }}>
                            <AccessTime fontSize="small" sx={{ mr: 0.5, color: '#636363', fontSize: '0.875rem' }} />
                            <Typography variant="caption" sx={{ 
                              color: '#636363', 
                              fontWeight: 500 
                            }}>
                              {duration} min
                            </Typography>
                          </Box>
                          <Box sx={{ 
                            height: '1px', 
                            width: '40px', 
                            background: 'linear-gradient(to right, #e0e0e0, rgba(93, 74, 125, 0.4), #e0e0e0)',
                            mt: 0.5
                          }} />
                        </Box>

                        {/* Arrival time */}
                        <Box sx={{ flex: 1, textAlign: 'right' }}>
                          <Typography variant="caption" color="text.secondary" sx={{ fontWeight: 500, fontSize: '0.7rem', letterSpacing: '0.5px' }}>
                            ARRIVAL
                          </Typography>
                          <Typography variant="h6" sx={{ 
                            fontWeight: 500, 
                            color: '#5d4a7d',
                            lineHeight: 1.2
                          }}>
                            {formatTime(bus.arrivalTime)}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                );
              })}
            </Box>
          </>
        )}

        {!errorMessage && searched && busResults.length === 0 && (
          <Box mt={3} display="flex" justifyContent="center">
            <Typography variant="body1" color="text.secondary">
              No results found. Try different stops.
            </Typography>
          </Box>
        )}
      </Paper>
      
      <InfoModal {...infoModalProps} />
    </Box>
  );
};

export default BusRouteSearch; 