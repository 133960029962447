import React, { useEffect, useRef, useState } from "react";
import { ArrivedCard } from "./busStatus/ArrivedCard";
import { FutureCard } from "./busStatus/FutureCard";
import { PastCard } from "./busStatus/PastCard";
import { TimelineItem } from "./busStatus/TimelineItem";
import InfoBar from "./busStatus/InfoBar";
import { SkeltonLoading } from './busStatus/Skelton'
import Switch from "react-switch";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';
import DirectionsBus from '@mui/icons-material/DirectionsBus';
import { getBusStatus } from "../api/bus";
import { useGeoLocation } from "./busStatus/useGeoLocation";
import { isLoggedIn } from "../api/account";
import InvalidGPSAlert from "./busStatus/InvalidGPSAlert";
import { pushGA4CustomEvent } from "../Analytics";
import { Box, Typography, Paper, Fab, IconButton, Divider, Chip, Link } from '@mui/material';
import ChatBubbleOutlinedIcon from '@mui/icons-material/ChatBubbleOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export function BusStatus({ 
  busId, 
  setShowBusStatusPage, 
  busInfo, 
  openLoginAlertBox, 
  gpsToggleChecked, 
  setGpsToggleChecked, 
  updateBusForumPage,
  routeData = null,
  hideHeader = false
}) {
  // State definitions
  const [navCityColor, setNavCityColor] = useState("#5d4a7d");
  const [backgroundStatus, setBackgroundStatus] = useState("#3B3B3B")
  const [textStatusColor, setTextStatusColor] = useState("#FFFFFF")
  const [denied, setDenied] = useState(true)
  const [loading, setLoading] = useState(routeData ? false : true)
  const [loadingRefresh, setLoadingRefresh] = useState(false)
  const [backgroundFuture, setBackgroundFuture] = useState("#FFFFFF");
  const [backgroundPast, setBackgroundPast] = useState("#eeeeee");
  const [backgroundArrivedColor, setBackgroundArrivedColor] = useState("#e5d4ff");
  const [showGpsToggle, setShowGpsToggle] = useState(false);
  const [invalidGpsAlert, setInvalidGpsAlert] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(30); // Single counter for all items

  const invalidGpsAlertOpen = () => { setInvalidGpsAlert(true) };
  const invalidGpsAlertClose = () => { setInvalidGpsAlert(false) };
  useGeoLocation(busId, gpsToggleChecked, invalidGpsAlertOpen)
  
  setTimeout(() => {
    const user = isLoggedIn()
    if (!user) {
      openLoginAlertBox();
    }
  }, 10000)
  
  // Initialize with routeData if provided, otherwise use empty state
  const [data, setData] = useState(routeData ? {
    isLive: routeData.isLive,
    pastData: routeData.past,
    futureData: routeData.future, 
    currentData: routeData.current,
    busRunningStatus: routeData.busRunningStatus
  } : {
    isLive: null,
    pastData: [],
    futureData: [],
    currentData: [],
    busRunningStatus: null
  });

  // Only fetch data if routeData is not provided
  useEffect(() => {
    if (routeData) {
      // If routeData is provided, use it directly
      setData({
        isLive: routeData.isLive,
        pastData: routeData.past,
        futureData: routeData.future,
        currentData: routeData.current,
        busRunningStatus: routeData.busRunningStatus
      });
      setLoading(false);
      
      // Still check GPS permissions
      navigator.geolocation.getCurrentPosition(
        position => {
          setDenied(false)
        }, 
        error => {
          setDenied(true)
        } 
      );
      
      // Scroll to current location after a delay
      setTimeout(() => {
        scrollView();
      }, 1000);
      
      return; // Exit early if routeData is provided
    }

    // Original data fetching logic below when routeData is not provided
    async function fetchData() {
      await getBusStatus(busId)
        .then((data) => {
          setData({
            ...data,
            isLive: data.isLive,
            futureData: data.future,
            currentData: data.current,
            pastData: data.past,
            busRunningStatus: data.busRunningStatus
          });
          setLoading(false)
        })
        .catch((err) => console.log(err));
      navigator.geolocation.getCurrentPosition(
        position => {
          setDenied(false)
        }, 
        error => {
          setDenied(true)
        } 
      );
      setTimeout(() => {
        setLoading(false)
        scrollView();
      }, 3000)
    }
    fetchData()
  }, [routeData, busId]);

  // Refresh counter logic
  useEffect(() => {
    let timer;
    if (loadingRefresh) {
      setRefreshCounter(30);
    } else {
      timer = setInterval(() => {
        setRefreshCounter(prev => {
          if (prev <= 1) {
            RefreshData();
            return 30;
          }
          return prev - 1;
        });
      }, 1000);
    }
    
    return () => clearInterval(timer);
  }, [loadingRefresh]);

  function scrollView() {
    if(!data.pastData || data.pastData.length === 0) return
    const mainRoot = document.getElementById("focusI");
    mainRoot?.scrollIntoView({ behavior: "smooth" });
  }
  
  useEffect(()=>{
    navigator.geolocation.getCurrentPosition(
      position => {
        setDenied(false)
      }, 
      error => {
        setDenied(true)
      } 
    );
  })
  
  async function RefreshData(){
    setLoadingRefresh(true)
    await getBusStatus(busId)
    .then((data) => {
      setData({
        ...data,
        futureData: data.future,
        currentData: data.current,
        isLive: data.isLive,
        pastData: data.past,
        busRunningStatus: data.busRunningStatus
      });
    })
    .catch((err) => console.log(err));
    setTimeout(() => {
      setLoadingRefresh(false);
    },1000)
  }
  
  const isChangeToggle = (nextChecked) => {
    setGpsToggleChecked(nextChecked);
    let pageTitle = "GPS toggle checked"
    let pageUrl = "gpsToggleChecked"
    pushGA4CustomEvent(pageTitle,pageUrl)
  };
  
  const openBusForum = () => {
    updateBusForumPage(true,busId,busInfo)
    setShowBusStatusPage(false)
  }
  
  useEffect(() => {
    if(denied == true || (data.busRunningStatus === "NOT_STARTED" || data.busRunningStatus === "ENDED")) {
      setShowGpsToggle(false)
    } else {
      setShowGpsToggle(true)
    }
  }, [denied, data])

  return (
    <Box sx={{ 
      width: '100%', 
      bgcolor: '#fafafa',
      minHeight: '100vh',
      position: 'relative'
    }}>
      {/* Fixed header section */}
      <Box sx={{ 
        position: 'sticky',
        top: 0, 
        zIndex: 1100,
        backgroundColor: '#fafafa',
        borderBottom: '1px solid rgba(0,0,0,0.09)',
        boxShadow: '0 1px 3px rgba(0,0,0,0.05)'
      }}>
        {/* Bus header */}
        {!hideHeader && (
          <Box sx={{ 
            bgcolor: navCityColor, 
            color: 'white',
            py: 1.5,
            px: 2,
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0 1px 3px rgba(0,0,0,0.12)'
          }}>
            <IconButton 
              onClick={() => setShowBusStatusPage(false)}
              sx={{ 
                color: 'white', 
                mr: 1.5,
                p: 0.5
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                fontWeight: 500,
                flex: 1
              }}
            >
              {busInfo}
            </Typography>
          </Box>
        )}
        
        {/* Status bar */}
        <Box sx={{ 
          bgcolor: 'white', 
          py: 1.5,
          px: 2,
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid rgba(0,0,0,0.09)',
          boxShadow: '0 1px 3px rgba(0,0,0,0.03)'
        }}>
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
          }}>
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              mr: 2
            }}>
              <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                mb: 0.5
              }}>
                <Box sx={{
                  bgcolor: data.isLive ? 'rgba(76, 175, 80, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                  borderRadius: '4px',
                  px: 1,
                  py: 0.25,
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <CircleIcon sx={{ 
                    color: data.isLive ? '#4caf50' : '#bdbdbd',
                    fontSize: '0.7rem',
                    mr: 0.75,
                    animation: data.isLive ? 'pulse 2s infinite' : 'none',
                    '@keyframes pulse': {
                      '0%': { opacity: 1 },
                      '50%': { opacity: 0.6 },
                      '100%': { opacity: 1 }
                    }
                  }} />
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      color: data.isLive ? '#2e7d32' : 'text.secondary',
                      fontWeight: 600,
                      fontSize: '0.7rem',
                      textTransform: 'uppercase',
                      letterSpacing: '0.5px'
                    }}
                  >
                    {data.isLive ? 'Live' : 'Scheduled'}
                  </Typography>
                </Box>
                
                <DirectionsBus sx={{ 
                  color: '#5d4a7d', 
                  ml: 1.5, 
                  fontSize: '1.1rem',
                  transform: 'rotate(-10deg)'
                }} />
              </Box>
              
              <Typography variant="body2" sx={{ color: 'text.primary', fontWeight: 500 }}>
                {data.isLive ? 'Live tracking active' : 'Using scheduled timings'}
              </Typography>
            </Box>
            
            <Divider orientation="vertical" flexItem sx={{ mx: 1.5, height: '70%' }} />
            
            <Box>
              {data.busRunningStatus === "NOT_STARTED" ? (
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Bus has not departed yet
                </Typography>
              ) : data.busRunningStatus === "ENDED" ? (
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Journey completed
                </Typography>
              ) : (
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Bus currently en route
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        
        {/* Bus Information at a glance */}
        {!loading && <InfoBar routeData={data} />}
        
        {/* Column headers */}
        <Box sx={{ 
          bgcolor: '#f5f5f7',
          py: 1.2,
          display: 'flex',
          borderBottom: '1px solid rgba(0,0,0,0.08)',
          boxShadow: '0 1px 2px rgba(0,0,0,0.03)'
        }}>
          <Box sx={{ 
            width: '30%', 
            pl: 3,
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Typography variant="caption" sx={{ fontWeight: 600, color: 'text.secondary', textTransform: 'uppercase', letterSpacing: '0.5px' }}>
              TIME
            </Typography>
          </Box>
          <Box sx={{ 
            width: '70%', 
            pr: 2,
            display: 'flex',
            justifyContent: 'flex-start'
          }}>
            <Typography variant="caption" sx={{ fontWeight: 600, color: 'text.secondary', textTransform: 'uppercase', letterSpacing: '0.5px' }}>
              BUS STOP
            </Typography>
          </Box>
        </Box>
      </Box>
      
      {/* Timeline content */}
      <Box 
        className="timeline-container"
        sx={{ position: 'relative', mt: 0, pb: 16 }}
      >
        {loading ? (
          <SkeltonLoading />
        ) : (
          <React.Fragment>
            {/* Journey Progress Summary */}
            <Box sx={{ 
              mb: 3, 
              mx: 2, 
              p: 2, 
              bgcolor: 'white', 
              borderRadius: 2,
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
            }}>
              <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                Journey Progress
              </Typography>
              
              <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                height: '16px',
                bgcolor: '#f5f5f7',
                borderRadius: '8px',
                overflow: 'hidden',
                mb: 1
              }}>
                {/* Calculate progress percentage based on completed stops */}
                {(() => {
                  const totalStops = (data.pastData?.length || 0) + 
                                     (data.currentData?.length || 0) + 
                                     (data.futureData?.length || 0);
                  
                  const completedStops = (data.pastData?.length || 0);
                  
                  // Add the current stop as half complete
                  const progress = totalStops > 0 
                    ? ((completedStops + (data.currentData?.length ? 0.5 : 0)) / totalStops) * 100
                    : 0;
                  
                  return (
                    <Box sx={{ 
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      height: '100%',
                      width: `${progress}%`,
                      bgcolor: '#5d4a7d',
                      borderRadius: '8px',
                      transition: 'width 0.5s ease'
                    }} />
                  );
                })()}
              </Box>
              
              <Box sx={{ 
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '0.75rem',
                color: 'text.secondary'
              }}>
                <Box>Origin</Box>
                <Box>Destination</Box>
              </Box>
            </Box>
            
            {/* Stops Timeline */}
            <Box sx={{ position: 'relative', zIndex: 2 }}>
              {data.pastData && data.pastData.length > 0 ? data.pastData.map((el, index) => (
                <TimelineItem
                  key={'past_'+index}
                  type="past"
                  time={el.time}
                  name={el.name}
                  isDelay={el.isDelay}
                  delay={el.delay}
                />
              )) : null}
              
              <Box id="focusI"></Box>
              
              {data.currentData && data.currentData.length > 0 ? data.currentData.map((el, index) => (
                <TimelineItem
                  key={'current_'+index}
                  type="current"
                  time={el.time}
                  name={el.name}
                  isDelay={el.isDelay}
                  delay={el.delay}
                  refreshCounter={refreshCounter}
                  onRefresh={RefreshData}
                  loadingRefresh={loadingRefresh}
                />
              )) : null}
              
              {data.futureData && data.futureData.length > 0 ? data.futureData.map((el, index) => (
                <TimelineItem
                  key={'future_'+index}
                  type="future"
                  time={el.time}
                  name={el.name}
                  isDelay={el.isDelay}
                  delay={el.delay}
                />
              )) : null}
            </Box>
            
            {/* Journey Summary Footer */}
            {(data.futureData && data.futureData.length > 0) && (
              <Box sx={{ 
                mt: 4, 
                mx: 2, 
                p: 2, 
                bgcolor: 'white', 
                borderRadius: 2,
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
                <Typography variant="body2" color="text.secondary">
                  Estimated arrival at destination
                </Typography>
                <Typography variant="h6" sx={{ color: '#5d4a7d', fontWeight: 600, mt: 1 }}>
                  {data.futureData[data.futureData.length-1].time}
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mt: 1,
                  fontSize: '0.75rem',
                  color: data.isLive ? '#2e7d32' : 'text.secondary'
                }}>
                  <CircleIcon sx={{ fontSize: '0.6rem', mr: 0.5, color: data.isLive ? '#2e7d32' : '#bdbdbd' }} />
                  {data.isLive ? 'Live tracking active' : 'Using estimated times'}
                </Box>
              </Box>
            )}
          </React.Fragment>
        )}
      </Box>
      
      {/* Discussion room button */}
      <Fab
        variant="extended"
        onClick={openBusForum}
        sx={{
          position: 'fixed',
          bottom: 'calc(16% + 48px)',
          right: '1rem',
          zIndex: 100,
          backgroundColor: '#5d4a7d',
          color: 'white',
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '0.875rem',
          px: 2,
          py: 1.5,
          borderRadius: '10px 10px 10px 2px',
          boxShadow: '0 2px 8px rgba(93, 74, 125, 0.3)',
          '&:hover': {
            backgroundColor: '#4d3e69',
          }
        }}
      >
        <ChatBubbleOutlinedIcon sx={{ mr: 1, fontSize: '1.25rem' }} />
        Public Discussion
      </Fab>
      
      {/* Status message at bottom */}
      <Paper 
        elevation={3}
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          p: 1.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderTop: '1px solid rgba(0,0,0,0.09)',
          bgcolor: '#fff',
          zIndex: 1000,
          boxShadow: '0px -2px 8px rgba(0,0,0,0.07)'
        }}
      >
        {/* Status message */}
        <Box sx={{ flex: 1 }}>
          {gpsToggleChecked ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box 
                sx={{ 
                  width: 10,
                  height: 10,
                  borderRadius: '50%', 
                  bgcolor: '#4caf50',
                  mr: 1.5,
                  animation: 'pulse 2s infinite',
                  '@keyframes pulse': {
                    '0%': { opacity: 1, boxShadow: '0 0 0 0 rgba(76, 175, 80, 0.4)' },
                    '70%': { opacity: 0.7, boxShadow: '0 0 0 6px rgba(76, 175, 80, 0)' },
                    '100%': { opacity: 1, boxShadow: '0 0 0 0 rgba(76, 175, 80, 0)' }
                  }
                }} 
              />
              <Box>
                <Typography variant="body2" sx={{ fontWeight: 500, color: '#4caf50', lineHeight: 1.3 }}>
                  Live tracking active
                </Typography>
                <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block' }}>
                  Reward countdown: 4:25
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box sx={{ width: '100%' }}>
              {(data.busRunningStatus === "NOT_STARTED") ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <DirectionsBus sx={{ color: '#9e9e9e', mr: 1, fontSize: '1.2rem' }} />
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    The bus is yet to depart from its origin
                  </Typography>
                </Box>
              ) : (data.busRunningStatus === "ENDED") ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <DirectionsBus sx={{ color: '#9e9e9e', mr: 1, fontSize: '1.2rem' }} />
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    The bus has reached its destination
                  </Typography>
                </Box>
              ) : (denied ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <LocationOnIcon sx={{ color: '#ff9800', mr: 1, fontSize: '1.2rem' }} />
                  <Typography variant="body2" sx={{ color: '#ff9800', fontWeight: 500 }}>
                    Please allow GPS permission for live status
                  </Typography>
                </Box>
              ) : (
                showGpsToggle && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocationOnIcon sx={{ color: '#5d4a7d', mr: 1, fontSize: '1.2rem' }} />
                    <Typography variant="body2" sx={{ color: '#5d4a7d', fontWeight: 500 }}>
                      Turn on GPS to get live bus location
                    </Typography>
                  </Box>
                )
              ))}
            </Box>
          )}
        </Box>
        
        {/* GPS Toggle */}
        {showGpsToggle && (
          <Box sx={{ ml: 2 }}>
            <Switch
              id={document.dir === "ltr" ? "react-switch" : "react-switch-rtl"}
              activeBoxShadow="0px 0px 1px 2px rgba(93, 74, 125, 0.4)"
              width={42}
              height={20}
              handleDiameter={18}
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={isChangeToggle}
              checked={gpsToggleChecked}
              onColor="#5d4a7d"
              offColor="#cccccc"
            />
          </Box>
        )}
      </Paper>
      
      {/* Refresh button */}
      <IconButton
        onClick={RefreshData}
        disabled={loadingRefresh}
        sx={{
          position: 'fixed',
          bottom: 'calc(25% + 48px)',
          right: '1rem',
          zIndex: 100,
          display: 'none', /* Hide this middle refresh button */
          backgroundColor: 'white',
          boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
          color: '#5d4a7d',
          '&:hover': {
            backgroundColor: '#f5f5f5',
          }
        }}
      >
        <RefreshIcon sx={{ 
          animation: loadingRefresh ? 'spin 1s linear infinite' : 'none',
          '@keyframes spin': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' }
          }
        }} />
      </IconButton>
      
      <InvalidGPSAlert 
        open={invalidGpsAlert} 
        invalidGpsAlertClose={invalidGpsAlertClose} 
        toggleGPS={isChangeToggle}
      />
    </Box>
  );
}

export default BusStatus
