import React from "react"
import { useState } from "react"
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@mui/icons-material/Send';
import { postBusForumMessage } from '../../api/bus'
import { isLoggedIn } from "../../api/account";
import { Box } from "@mui/material";

export default function PostMessageBox({ busId, refreshMessages, setLoader }) {
   const user = isLoggedIn()
   const [message, setMessage] = useState("")

   const onUserChangedText = (event) => {
     setMessage(event.target.value);
   };

   const onClickSendIcon = async () => {
     if (!message.trim()) return; // Don't send empty messages
     
     setLoader(true)
     await postBusForumMessage(busId, message, user.name)
     setMessage("")
     setTimeout(refreshMessages, 2000);
   };

   // Handle Enter key press to send message
   const handleKeyDown = (event) => {
     if (event.key === 'Enter' && !event.shiftKey) {
       event.preventDefault();
       onClickSendIcon();
     }
   };

   return (
     <Box sx={{ 
       p: 1, 
       bgcolor: 'background.paper',
       borderTop: '1px solid rgba(0, 0, 0, 0.12)'
     }}>
       <Grid container alignItems="center" spacing={1}>
         <Grid item xs={10}>
           <TextField 
             multiline 
             rows={2} 
             id="outlined-basic-email" 
             label="Post a public message" 
             value={message} 
             onChange={onUserChangedText}
             onKeyDown={handleKeyDown}
             fullWidth
             variant="outlined"
             size="small"  
           />
         </Grid>
         <Grid item xs={2} container justifyContent="center">
           <Fab 
             color="primary" 
             aria-label="send" 
             size="small"
             onClick={onClickSendIcon}
             disabled={!message.trim()}
             sx={{ bgcolor: '#800080' }}
           >
             <SendIcon fontSize="small" />
           </Fab>
         </Grid>
       </Grid>
     </Box>
   );
}
