import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Paper, 
  TextField, 
  Button, 
  Box, 
  Snackbar, 
  Alert, 
  CircularProgress,
  Backdrop,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { useForm, Controller } from "react-hook-form";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { updateBusTime } from '../../api/bus';
import { getFormattedTimeString, getTimeOfDayInSec } from '../../util/time';
import './BusTimeUpdate.css';
import { parse } from 'date-fns';

const BusTimeUpdate = ({ 
  onClose, 
  busTimeData, 
  busStop, 
  busDirection, 
  onSuccess 
}) => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editingItem, setEditingItem] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const { control, handleSubmit, reset, setValue } = useForm();

  // Function to convert time string to Date object
  const parseTimeString = (timeString) => {
    if (!timeString) return new Date();
    
    try {
      // Handle various time formats
      if (timeString.includes(':')) {
        // Try to parse standard time format (HH:MM AM/PM or HH:MM)
        const dateParts = timeString.match(/(\d+):(\d+)\s*(AM|PM)?/i);
        if (dateParts) {
          const hours = parseInt(dateParts[1]);
          const minutes = parseInt(dateParts[2]);
          const period = dateParts[3] ? dateParts[3].toUpperCase() : null;
          
          const date = new Date();
          date.setHours(0, 0, 0, 0); // Reset to midnight
          
          if (period === 'PM' && hours < 12) {
            date.setHours(hours + 12);
          } else if (period === 'AM' && hours === 12) {
            date.setHours(0);
          } else {
            date.setHours(hours);
          }
          
          date.setMinutes(minutes);
          return date;
        }
      }
      
      // Fallback to generic parsing
      return parse(timeString, 'hh:mm a', new Date());
    } catch (e) {
      console.error("Error parsing time:", e);
      return new Date();
    }
  };

  const handleEditItem = (item) => {
    setEditingItem(item);
    setValue('busName', item.busName);
    
    // Use the custom parser to handle time formats
    const timeDate = parseTimeString(item.busTime);
    setValue('busTime', timeDate);
  };

  const handleCancelEdit = () => {
    setEditingItem(null);
    reset();
  };

  const handleAddNewTime = () => {
    setEditingItem({
      isNew: true,
      busId: null,
      busName: '',
      busTime: ''
    });
    reset();
  };

  const handleDeleteConfirmOpen = () => {
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirmClose = () => {
    setDeleteConfirmOpen(false);
  };

  const handleDeleteBus = async () => {
    setDeleteConfirmOpen(false);
    setLoading(true);
    try {
      const deleteData = {
        busStop: busStop,
        busDirection: busDirection,
        busId: editingItem.busId,
        isNew: false,
        oldBusName: editingItem.busName,
        oldBusTime: editingItem.busTime,
        newBusName: editingItem.busName,
        newBusTime: editingItem.busTime,
        newBusTimeOfDay: getTimeOfDayInSec(parseTimeString(editingItem.busTime)),
        delete: true
      };

      const response = await updateBusTime(deleteData);
      
      if (response && response.success) {
        setSuccessMessage('Bus time deletion submitted successfully. Our team will review it shortly.');
        setEditingItem(null);
        reset();
        if (onSuccess) {
          onSuccess();
        }
      } else {
        setErrorMessage(response?.message || 'Failed to submit your request. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting bus time deletion:', error);
      setErrorMessage('An error occurred while submitting your request.');
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      // Create base update data
      const updateData = {
        busStop: busStop,
        busDirection: busDirection,
        isNew: editingItem.isNew || false,
        newBusName: data.busName,
        newBusTime: getFormattedTimeString(data.busTime),
        newBusTimeOfDay: getTimeOfDayInSec(data.busTime)
      };

      // Add fields specific to update vs new bus
      if (editingItem.isNew) {
        // For new bus, we don't need oldBusName/oldBusTime, and busId is undefined (not null)
        // This avoids sending empty strings "" which could cause issues
      } else {
        // For updates, include the old values and busId
        updateData.busId = editingItem.busId;
        updateData.oldBusTime = editingItem.busTime;
        updateData.oldBusName = editingItem.busName;
      }

      const response = await updateBusTime(updateData);
      
      if (response && response.success) {
        setSuccessMessage(editingItem.isNew 
          ? 'New bus time added successfully! It will be reviewed before publishing.' 
          : 'Bus time updated successfully! Changes will be reviewed before publishing.');
        setEditingItem(null);
        reset();
        if (onSuccess) {
          onSuccess();
        }
      } else {
        setErrorMessage(response?.message || 'Failed to submit your request. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting bus time update:', error);
      setErrorMessage('An error occurred while submitting your request.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="bus-time-update-container">
      <Paper elevation={3} className="bus-time-update-paper">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h5" component="h2">
            Update Bus Times
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ 
          backgroundColor: 'rgba(128, 0, 128, 0.1)', 
          p: 2, 
          borderRadius: 1,
          mb: 2
        }}>
          <Typography variant="body1" fontWeight="bold" gutterBottom>
            You are updating bus times for:
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Bus Stop:</strong> {busStop}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Direction:</strong> Towards {busDirection}
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {editingItem ? (
          <Box component="form" onSubmit={handleSubmit(onSubmit)} className="bus-time-edit-form">
            <Typography variant="h6">
              {editingItem.isNew ? 'Add New Bus Time' : 'Edit Bus Time'}
            </Typography>
            
            {!editingItem.isNew && (
              <Box sx={{ 
                backgroundColor: 'rgba(0, 0, 0, 0.05)', 
                p: 1.5, 
                borderRadius: 1,
                mb: 2,
                mt: 1,
                display: 'flex',
                flexDirection: 'column'
              }}>
                <Typography variant="body2" color="textSecondary">
                  Current information:
                </Typography>
                <Typography variant="body1" fontWeight="medium">
                  Time: <strong>{editingItem.busTime}</strong> | Bus: <strong>{editingItem.busName}</strong>
                </Typography>
                {editingItem.routeName && (
                  <Typography variant="body2" color="textSecondary" mt={0.5}>
                    Route: {editingItem.routeName}
                  </Typography>
                )}
              </Box>
            )}
            
            <Box mb={2} mt={2}>
              <Controller
                name="busName"
                control={control}
                defaultValue={editingItem.busName}
                rules={{ required: 'Bus name is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Bus Name"
                    variant="outlined"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    size="small"
                  />
                )}
              />
            </Box>

            <Box mb={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  name="busTime"
                  control={control}
                  defaultValue={editingItem.isNew ? new Date() : parseTimeString(editingItem.busTime)}
                  rules={{ required: 'Bus time is required' }}
                  render={({ field, fieldState: { error } }) => (
                    <MobileTimePicker
                      label="Bus Time"
                      {...field}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          size="small"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Box display="flex" gap={2}>
              <Button
                variant="outlined"
                onClick={handleCancelEdit}
                disabled={loading}
              >
                Cancel
              </Button>

              {!editingItem.isNew && (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleDeleteConfirmOpen}
                  disabled={loading}
                >
                  Delete
                </Button>
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : (editingItem.isNew ? 'Add' : 'Update')}
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddNewTime}
              sx={{ mb: 2 }}
            >
              Add New Bus Time
            </Button>

            <List className="bus-time-list">
              {busTimeData && busTimeData.map((item, index) => (
                <ListItem 
                  key={`${item.busId}-${index}`} 
                  divider 
                  className="bus-time-list-item"
                  secondaryAction={
                    <IconButton edge="end" onClick={() => handleEditItem(item)}>
                      <EditIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={`${item.busTime} - ${item.busName}`}
                    secondary={item.routeName || ''}
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Paper>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={!!successMessage}
        autoHideDuration={8000}
        onClose={() => setSuccessMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSuccessMessage('')} 
          severity="success" 
          sx={{ 
            width: '100%',
            '& .MuiAlert-message': {
              fontSize: '1rem',
              fontWeight: 'medium'
            }
          }}
          variant="filled"
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert 
          onClose={() => setErrorMessage('')} 
          severity="error" 
          sx={{ width: '100%' }}
          variant="filled"
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 2, minWidth: '300px' }}>
          <Typography id="alert-dialog-title" variant="h6" component="h2" mb={2}>
            Delete Bus Time?
          </Typography>
          <Typography id="alert-dialog-description" variant="body1" mb={2}>
            Are you sure you want to delete the {editingItem?.busTime} {editingItem?.busName} bus?
          </Typography>
          <Typography variant="body2" color="textSecondary" mb={2}>
            Your submission will be reviewed before any changes are published.
          </Typography>
          <Box display="flex" justifyContent="flex-end" gap={1}>
            <Button onClick={handleDeleteConfirmClose} variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleDeleteBus} variant="contained" color="error" autoFocus>
              Delete
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Container>
  );
};

export default BusTimeUpdate; 