
export const getFormattedTimeString = (dateString)  => {
    const date = new Date(dateString)
    let hours = date.getHours();
    let timeOfDay = 'AM';
    if (hours > 12) {
        hours = hours - 12;
        timeOfDay = 'PM';
    }
    const minutes = date.getMinutes();
    return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')} ${timeOfDay}`;
}

export const getTimeOfDayInSec = (dateString)  => {
    const date = new Date(dateString)
    let hours = date.getHours();
    const minutes = date.getMinutes();

    return (hours * 60 * 60) + ( minutes * 60)
}
